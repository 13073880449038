import React from 'react'
import styled from 'styled-components'
import Logo from '../logo'
import { variable, title } from '../../data/var'
import Progress from '../progress-dots'
import { SectionWrap, Title, md, P } from '../../common'

export default ({ setPage }) => (
  <SectionWrap style={{minHeight: '100vh'}}>
    <Progress num={4}/>
    <Title>{title}</Title>
    <div>
      <Wrap>
      {
        variable.map((v, index) => {
          return  <div key={index}>
                    <input onClick={setPage} type="radio" name='variable' id={v} value={v} style={{display: 'none'}}/>
                    <label htmlFor={v}>
                      <P style={{boxShadow: '3px 7px 10px #888888'}}>{v}</P>
                    </label>
                  </div>
        })
      }
      </Wrap>
      <Logo />
    </div>
    <br />
  </SectionWrap>
)

export const Wrap = styled.div`
  max-width:800px;
  margin:auto;

  ${md} {
    margin: 0 1rem;
  }
`
