import React from 'react'
import styled from 'styled-components'
import Logo from '../logo'
import { timeOfDay } from '../../data/timeOfDay'
import Progress from '../progress-dots'
import { Wrap } from './apt-date'
import { SectionWrap, colors, Title } from '../../common'

export const Square = styled.div`
  color: ${colors.primaryContrast};
  background: ${colors.primaryColor};
  margin: 1rem auto;
  max-width: 300px;
  cursor:pointer;
  min-width: 150px;
  min-height: 150px;
  box-shadow: 3px 7px 10px #888888;
`

export default ({ setPage }) => (
  <SectionWrap style={{minHeight: '100vh'}}>
  <Progress num={2}/>
    <Title>What time <Icon /> would work best for your schedule?</Title>
    <p css={`font-size:12px;`}>Eastern Standard Times</p>
    <div>
      <Wrap>
      {
        timeOfDay.map((time, index) => {
          return  <Square key={index}>
                    <input onClick={setPage} type="radio" name='time' id={time.value} value={time.value} style={{display: 'none'}}/>
                    <label htmlFor={time.value}>
                      <p css={`padding:15%;font-weight:700;`}>{time.start} <br /> to  <br /> {time.end}</p>
                    </label>
                  </Square>
        })
      }
      </Wrap>
      <Logo />
    </div>
    <br />
  </SectionWrap>
)

const Icon = () => (
  <span>
    <svg css={`transform:translate(0, 3px);`} height='25px' width='25px' fill={colors.primaryColor} viewBox="0 0 35 35" version="1.1" x="0px" y="0px"><title>clock-icon@1x</title><desc>Created with Sketch.</desc><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g transform="translate(-806.000000, -2730.000000)" fill={colors.primaryColor}><g transform="translate(-14.000000, -75.000000)"><g transform="translate(307.000000, 2555.000000)"><g transform="translate(0.000000, 237.000000)"><g transform="translate(513.000000, 13.000000)"><path d="M17.3076923,31.6934016 C9.363018,31.6934016 2.92441999,25.2523666 2.92441999,17.3101293 C2.92441999,9.36545502 9.363018,2.92441999 17.3076923,2.92441999 C25.2523666,2.92441999 31.6909646,9.36545502 31.6909646,17.3101293 C31.6909646,25.2523666 25.2523666,31.6934016 17.3076923,31.6934016 M17.3076923,0 C26.8656716,0 34.6153846,7.74971297 34.6153846,17.3101293 C34.6153846,26.8681087 26.8656716,34.6153846 17.3076923,34.6153846 C7.74971297,34.6153846 0,26.8681087 0,17.3101293 C0,7.74971297 7.74971297,0 17.3076923,0 Z M17.3076923,5.85005849 C16.4986028,5.85005849 15.8454823,6.50561597 15.8454823,7.3147055 L15.8454823,17.2869777 C15.8454823,17.2894147 15.8454823,17.2918517 15.8454823,17.2942887 C15.8430453,17.6744633 15.9819552,18.0546379 16.2719602,18.3446429 L23.3393085,25.4095542 C23.6244395,25.6971222 23.997303,25.8384691 24.3726036,25.8384691 C24.7454671,25.8384691 25.1207677,25.6971222 25.4058987,25.4095542 C25.9761606,24.8392923 25.9761606,23.913226 25.4058987,23.3429641 L18.7699023,16.7069677 L18.7699023,7.3147055 C18.7699023,6.50561597 18.1167818,5.85005849 17.3076923,5.85005849 Z"></path></g></g></g></g></g></g></svg>
  </span>
)
