import React from 'react'
import Logo from '../logo'
import { rush } from '../../data/rush'
import Progress from '../progress-dots'
import { Wrap } from './apt-var'
import { SectionWrap, Title, P } from '../../common'

export default ({ setPage }) => (
  <SectionWrap style={{minHeight: '100vh'}}>
  <Progress num={3}/>
    <Title>How soon would you like to schedule your appointment?</Title>
    <div>
      <Wrap>
      {
        rush.map((time, index) => {
          return  <div key={index}>
                    <input onClick={setPage} type="radio" name='speed' id={time} value={time} style={{display: 'none'}}/>
                    <label htmlFor={time}>
                      <P style={{boxShadow: '3px 7px 10px #888888'}}>{time}</P>
                    </label>
                  </div>
        })
      }
      </Wrap>
      <Logo />
    </div>
    <br />
  </SectionWrap>
)
