import React from 'react'
import styled from 'styled-components'
import { colors } from '../common'

export default ({ num }) => (
  <div css={`display:flex;margin:auto;padding-bottom:2rem;position:absolute;top:20px;left:calc(50vw - 35px);`}>
    <Dots style={{backgroundColor: num === 1 ? colors.primaryColor : colors.primaryContrast }}/>
    <Dots style={{backgroundColor: num === 2 ? colors.primaryColor : colors.primaryContrast }}/>
    <Dots style={{backgroundColor: num === 3 ? colors.primaryColor : colors.primaryContrast }}/>
    <Dots style={{backgroundColor: num === 4 ? colors.primaryColor : colors.primaryContrast }}/>
    {
      // <Dots style={{backgroundColor: num === 5 ? colors.primaryColor : colors.primaryContrast }}/>
    }
  </div>
)

const Dots = styled.div`
  height:10px;
  width:10px;
  border: 2px solid ${colors.primaryColor};
  border-radius: 50%;
  margin: 3px;
`
