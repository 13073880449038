import React from 'react'
import styled from 'styled-components'
import Logo from '../logo'
import Progress from '../progress-dots'
import { dates } from '../../data/dates'
import { SectionWrap, md, Title, colors } from '../../common'

export default ({ setPage }) => (
  <SectionWrap style={{minHeight: '100vh'}}>
    <Progress num={1}/>
    <Title>What Day <Icon /> would you like to schedule your free consultation?</Title>
    <div>
      <Wrap>
      {
        dates.map((time, index) => {
          let abrv = 'Thurs'
          switch(time) {
            case 'Monday' : abrv = 'Mon'
            break
            case 'Tuesday' : abrv = 'Tues'
            break
            case 'Wednesday' : abrv = 'Wed'
            break
            case 'Thursday' : abrv = 'Thurs'
            break
            case 'Friday' : abrv = 'Fri'
            break
            case 'Saturday' : abrv = 'Sat'
            break
            default: abrv = 'Sun'
          }
          return  <Square key={index}>
                    <input onClick={setPage} type="radio" aria-label={`pick ${time}`} name='day' id={time} value={time} style={{display: 'none'}}/>
                    <label htmlFor={time}>
                      <p css={`padding: 35%;margin: 3px 0 0 -6px;`}>{abrv}</p>
                    </label>
                  </Square>
        })
      }
      </Wrap>
      <br />
      <Logo />
    </div>
    <br />
  </SectionWrap>
)

const Icon = () => (
  <span>
    <svg css={`transform:translate(0, 6px);`} height='35px' width='35px'  fill={colors.primaryColor}version="1.1" x="0px" y="0px" viewBox="0 0 100 100" ><g><g><g><path d="M87.5,15.3h-9v-3.4c0-5.2-4.2-9.4-9.4-9.4c-5.2,0-9.4,4.2-9.4,9.4v3.4H40.2v-3.4c0-5.2-4.2-9.4-9.4-9.4s-9.4,4.2-9.4,9.4     v3.4h-9c-5,0-9.1,4.1-9.1,9.1v64c0,5,4.1,9.1,9.1,9.1h75.1c5,0,9.1-4.1,9.1-9.1v-64C96.6,19.4,92.6,15.3,87.5,15.3z M64.7,11.9     c0-2.4,2-4.4,4.4-4.4c2.4,0,4.4,2,4.4,4.4v11.8c0,2.4-2,4.4-4.4,4.4c-2.4,0-4.4-2-4.4-4.4V11.9z M26.5,11.9c0-2.4,2-4.4,4.4-4.4     c2.4,0,4.4,2,4.4,4.4v11.8c0,2.4-2,4.4-4.4,4.4c-2.4,0-4.4-2-4.4-4.4V11.9z M12.5,20.2h9v3.4c0,5.2,4.2,9.4,9.4,9.4     s9.4-4.2,9.4-9.4v-3.4h19.5v3.4c0,5.2,4.2,9.4,9.4,9.4c5.2,0,9.4-4.2,9.4-9.4v-3.4h9c2.3,0,4.1,1.9,4.1,4.1v15.1H8.3V24.4     C8.3,22.1,10.2,20.2,12.5,20.2z M87.5,92.5H12.5c-2.3,0-4.1-1.9-4.1-4.1v-44h83.3v44C91.7,90.7,89.8,92.5,87.5,92.5z"></path><path d="M31.3,53.1H20.5c-1.3,0-2.4,1.1-2.4,2.4v6.9c0,1.3,1.1,2.4,2.4,2.4h10.8c1.3,0,2.4-1.1,2.4-2.4v-6.9     C33.7,54.2,32.6,53.1,31.3,53.1z"></path><path d="M79.5,53.1H68.7c-1.3,0-2.4,1.1-2.4,2.4v6.9c0,1.3,1.1,2.4,2.4,2.4h10.8c1.3,0,2.4-1.1,2.4-2.4v-6.9     C82,54.2,80.9,53.1,79.5,53.1z"></path><path d="M55.4,53.1H44.6c-1.3,0-2.4,1.1-2.4,2.4v6.9c0,1.3,1.1,2.4,2.4,2.4h10.8c1.3,0,2.4-1.1,2.4-2.4v-6.9     C57.8,54.2,56.8,53.1,55.4,53.1z"></path><path d="M31.3,72.3H20.5c-1.3,0-2.4,1.1-2.4,2.4v6.9c0,1.3,1.1,2.4,2.4,2.4h10.8c1.3,0,2.4-1.1,2.4-2.4v-6.9     C33.7,73.4,32.6,72.3,31.3,72.3z"></path><path d="M79.5,72.3H68.7c-1.3,0-2.4,1.1-2.4,2.4v6.9c0,1.3,1.1,2.4,2.4,2.4h10.8c1.3,0,2.4-1.1,2.4-2.4v-6.9     C82,73.4,80.9,72.3,79.5,72.3z"></path><path d="M55.4,72.3H44.6c-1.3,0-2.4,1.1-2.4,2.4v6.9c0,1.3,1.1,2.4,2.4,2.4h10.8c1.3,0,2.4-1.1,2.4-2.4v-6.9     C57.8,73.4,56.8,72.3,55.4,72.3z"></path></g></g></g></svg>
  </span>
)

export const Wrap = styled.div`
  max-width:800px;
  margin:auto;
  display: flex;
  flex-wrap:wrap;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-dirextion:column;

  ${md} {
    margin: 0 1rem;
    justify-content:space-around;
  }
`

export const Square = styled.div`
  color: ${colors.primaryContrast};
  background: ${colors.primaryColor};
  margin: 1rem auto;
  max-width: 300px;
  cursor:pointer;
  min-width: 100px;
  min-height: 100px;
  font-weight: 700;
  border-radius: 50%;
  box-shadow: 3px 7px 10px #888888;
`
