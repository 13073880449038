import React, { useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

import AptForm from '../components/appointment/apt-form'
import AptDate from '../components/appointment/apt-date'
import AptTime from '../components/appointment/apt-time'
import AptRush from '../components/appointment/apt-rush'
// import AptVar from '../components/appointment/apt-var'

export default () => {
  const [ page, setPage ] = useState('AptDay')

  // function SetAptDay () { setPage('AptDay') }
  function SetAptTime () { setPage('AptTime') }
  function SetAptRush () { setPage('AptRush') }
  // function SetAptVar () { setPage('AptVar') }
  function SetAptFinish () { setPage('AptFinish') }

  return(
  <Layout>
    <SEO title={`Schedule Your Appointment with TTG`} />
    <input name="_sendgrid_receipt" readOnly hidden />

    <div style={{display: page === 'AptDay' ? 'block' : 'none'}}>
      <AptDate setPage={SetAptTime}/>
    </div>

    <div style={{display: page === 'AptTime' ? 'block' : 'none'}}>
      <AptTime setPage={SetAptRush} />
    </div>

    <div style={{display: page === 'AptRush' ? 'block' : 'none'}}>
      <AptRush setPage={SetAptFinish}/>
    </div>

{
    // <div style={{display: page === 'AptVar' ? 'block' : 'none'}}>
    //   <AptVar setPage={SetAptFinish}/>
    // </div>
}

    <div style={{display: page === 'AptFinish' ? 'block' : 'none'}}>
      <AptForm />
    </div>

  </Layout>
)}
