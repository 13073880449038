import React, { useState } from 'react'
import styled from 'styled-components'
import Logo from '../logo'
import Progress from '../progress-dots'
import { handleSubmit } from '../../components/formWrap'
import { md, colors, SectionWrap, Title } from '../../common'

export default () => {

  return(
  <SectionWrap>
  <Progress num={4}/>
    <Title>How would you like us to confirm your appointment?</Title>
    <p css={`font-size:12px;`}>(Select all that apply)</p>
    <Icons />
    <div css={`width: 80%;margin:auto;height:1.5px;background:${colors.primaryColor};margin-bottom:2rem;`}/>
    <Wrap>
      <Label htmlFor="first_name">First Name:</Label>
      <Input type="text" name="first_name" required placeholder="required" />
      <Label htmlFor="last_name">Last Name:</Label>
      <Input type="text" name="last_name" required placeholder="required" />
      <Label htmlFor="email">Email:</Label>
      <Input type="email" name="email" required placeholder="required" />
      <Label htmlFor="phone-apt">Mobile Number:</Label>
      <Input type="text" name="phone" required placeholder="required" />
      <Label htmlFor="message">Message:</Label>
      <Message type="text" name="message" rows='5' placeholder="Please share what you'd like to discuss" />
      <Btn onClick={handleSubmit} type={`submit`} >Request Appointment</Btn>
    </Wrap>
    <Logo />
    <br /><br /><br />
  </SectionWrap>
)}

const Icons = () => {
  const [ emailSelect, setEmail ] = useState(false)
  const [ phoneSelect, setPhone ] = useState(false)
  const [ textSelect, setText ] = useState(false)

  return(
  <div css={`display:flex;justify-content:space-around;width:600px;margin:auto;padding-bottom:3rem;${md}{width:90%;}`}>

    <IconBtn style={{border: `3px solid ${phoneSelect ? colors.primaryColor : '#fff'}` }} onChange={() => setPhone(!phoneSelect)} title="phone">
      <input type="checkbox" name='confirm-apt-phone' id='phone-method' style={{display: 'none'}}/>
      <label htmlFor='phone-method'>
        <svg css={`margin-top:15px;`} height='50px' width='50px' fill={colors.primaryColor} x="0px" y="0px" viewBox="0 0 100 100"><path d="M28.6,5.4c1,0,1.9,0.3,2.7,0.9c0.4,0.3,0.7,0.6,1,1l10.1,15.5c0.6,1,0.9,2.1,0.6,3.3c-0.2,1.1-0.9,2.1-1.9,2.8L25.7,39  c-1.9,1-2.9,3.2-2.3,5.4c3.8,14,13.2,26.2,25.8,33.5c0.7,0.4,1.5,0.6,2.3,0.6c1.2,0,2.3-0.4,3.2-1.3l13.4-12.5  c0.8-0.8,1.9-1.2,3-1.2c1.2,0,2.4,0.5,3.2,1.4L87,78.4c0.4,0.4,0.7,0.9,0.9,1.4c0.6,1.7,0.2,3.6-1.1,4.8l-6.4,6  c-2.7,2.6-6.3,4-9.8,4c-0.5,0-1,0-1.5-0.1c0,0-0.1,0-0.1,0c-2.2-0.3-4.4-0.6-6.5-1.1c-30.6-6.8-52-34.5-50.8-65.9  c0.1-1.8,0.2-3.6,0.4-5.5c0-0.3,0.1-0.7,0.1-1c0.6-4.1,2.9-7.8,6.5-10.2l7.3-4.8C26.9,5.6,27.7,5.4,28.6,5.4 M28.6,0.7  c-1.7,0-3.4,0.5-5,1.5L16.3,7c-4.7,3.1-7.9,7.9-8.6,13.5c0,0.4-0.1,0.7-0.1,1.1c-0.2,2-0.4,3.9-0.4,5.9C5.9,60.9,28.9,90.7,61.6,98  c2.3,0.5,4.6,0.9,7,1.2c0,0,0.1,0,0.1,0c0.7,0.1,1.4,0.1,2,0.1c4.8,0,9.4-2,13-5.3l6.4-6c2.8-2.6,3.5-6.5,2.3-9.9  c-0.4-1.1-1-2.1-1.9-2.9L77.8,61.7c-1.8-1.9-4.2-2.9-6.6-2.9c-2.2,0-4.5,0.8-6.2,2.4L51.6,73.8C40,67.1,31.5,56,28,43.1  c0.1,0,0.1-0.1,0.2-0.1l15.5-10.1c4.2-2.7,5.4-8.4,2.6-12.6L36.2,4.8c-0.6-0.9-1.3-1.6-2.1-2.2C32.5,1.3,30.6,0.7,28.6,0.7L28.6,0.7  z"></path></svg>
      </label>
      <p css={`font-weight:700;text-transform:uppercase;margin-top:30px;color: ${ phoneSelect ? 'inherit' : 'transparent'}`}>Call Me</p>
    </IconBtn>

    <IconBtn style={{border: `3px solid ${emailSelect ? colors.primaryColor : '#fff'}`}} onChange={() => setEmail(!emailSelect)} title="email">
      <input type="checkbox" name='confirm-apt-email' id='email-method'  style={{display: 'none'}}/>
      <label htmlFor='email-method'>
      <svg css={`margin-top: 6px;`} height='70px' width='70px' fill={colors.primaryColor} x="0px" y="0px" viewBox="0 0 100 100" ><g><path d="M23,73h54c2.76,0,5-2.24,5-5V29c0-2.76-2.24-5-5-5H23c-2.76,0-5,2.24-5,5v39C18,70.76,20.24,73,23,73z M75.11,28   L50.77,51.73c-0.42,0.42-1.11,0.42-1.55-0.02L24.89,28H75.11z M22,30.77l24.41,23.79c0.99,0.99,2.3,1.49,3.6,1.49   c1.3,0,2.59-0.49,3.57-1.47L78,30.77V68c0,0.55-0.45,1-1,1H23c-0.55,0-1-0.45-1-1V30.77z"></path></g></svg>
      </label>
      <p css={`font-weight:700;text-transform:uppercase;margin-top:20px;color: ${ emailSelect ? 'inherit' : 'transparent'}`}>Email Me</p>
    </IconBtn>

    <IconBtn style={{border: `3px solid ${textSelect ? colors.primaryColor : '#fff'}` }} onChange={() => setText(!textSelect)} title="Text Message">
      <input type="checkbox" name='confirm-apt-text' id='text-method' value='text' style={{display: 'none'}}/>
      <label htmlFor='text-method'>
        <svg css={`margin:10px 0 0 2px;`} height='60px' width='60px'  fill={colors.primaryColor} version="1.1" x="0px" y="0px" viewBox="0 0 100 100"><g><g><g><path d="M68.2,15.2H31.8c-16.1,0-29.3,13.1-29.3,29.3c0,9.7,4.8,18.7,12.7,24.2c-1.3,2.5-2.6,4.7-3.9,6.5c-1.5,2.1-1.6,4.8-0.1,7     c1.2,1.7,3,2.6,5,2.6c0.5,0,1.1-0.1,1.6-0.2c9.6-2.6,18.3-7.2,24.2-10.8h26.3c16.1,0,29.3-13.1,29.3-29.3S84.4,15.2,68.2,15.2z      M68.2,68.4H41.2c-0.5,0-1,0.1-1.4,0.4c-4.5,2.8-13.6,7.9-23.4,10.6c-0.3,0.1-0.5,0-0.7-0.3c-0.2-0.3-0.2-0.5,0-0.8  c1.9-2.6,3.8-5.8,5.5-9.5c0.6-1.3,0.1-2.8-1.1-3.5C12.6,61.1,7.9,53.1,7.9,44.5c0-13.2,10.7-23.9,23.9-23.9h36.5 c13.2,0,23.9,10.7,23.9,23.9S81.4,68.4,68.2,68.4z"></path><path d="M33.3,39.1h-7.2c-1,0-1.8,0.8-1.8,1.8v7.2c0,1,0.8,1.8,1.8,1.8h7.2c1,0,1.8-0.8,1.8-1.8v-7.2     C35.1,39.9,34.3,39.1,33.3,39.1z"></path><path d="M53.6,39.1h-7.2c-1,0-1.8,0.8-1.8,1.8v7.2c0,1,0.8,1.8,1.8,1.8h7.2c1,0,1.8-0.8,1.8-1.8v-7.2     C55.4,39.9,54.6,39.1,53.6,39.1z"></path><path d="M73.9,39.1h-7.2c-1,0-1.8,0.8-1.8,1.8v7.2c0,1,0.8,1.8,1.8,1.8h7.2c1,0,1.8-0.8,1.8-1.8v-7.2     C75.7,39.9,74.9,39.1,73.9,39.1z"></path></g></g></g></svg>
      </label>
      <p css={`font-weight:700;text-transform:uppercase;margin-top:26px;color: ${ textSelect ? 'inherit' : 'transparent'}`}>Text Me</p>
    </IconBtn>
  </div>
)}

const Wrap = styled.div`
  display:flex;
  justify-content:center;
  width:100%;
  max-width:600px;
  margin:auto;
  flex-direction: column;
  text-align:left;

  ${md} {
    margin: 0 1rem;
    width: 90%;
  }
`

const Label = styled.label`
  text-transform: uppercase;
  margin: 0;
  font-size:12px;
  font-weight:700;
`

const Input = styled.input`
  margin-bottom: .5rem;
  padding:1rem;
  border:.5px solid dimgrey;
  font-size: 16px;

  ::placeholder {
    color: greylight;
    font-size: 14px;
    font-style:italic;
  }
`

const Message = styled.textarea`
  margin-bottom: .5rem;
  padding:1rem;
  border:.5px solid dimgrey;
  font-size: 16px;

  ::placeholder {
    color: greylight;
    font-size: 14px;
    font-style:italic;
  }
`

const Btn = styled.button`
  border:0;
  padding:1rem;
  background:${colors.primaryColor};
  color:${colors.primaryContrast};
  text-transform:uppercase;
  font-size:1rem;
  font-weight:800;
  cursor:pointer;
  margin-top:1rem;
`

const IconBtn = styled.div`
  background-color: #fff;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  box-shadow: 3px 7px 10px #888888;
`
